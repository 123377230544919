import React, { useState } from 'react';
import { Container, Jumbotron, Form, Image, Badge, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faHotdog } from '@fortawesome/free-solid-svg-icons'
import Thumbnail from './thumbnail.png';
import './App.css';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';

import { Storage, Predictions } from 'aws-amplify';
import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';

// Initialise Amplify
Amplify.configure(awsconfig);
Amplify.addPluggable(new AmazonAIPredictionsProvider());

function App() {

  const [processed, setProcessed] = useState(false);  
  const [processing, setProcessing] = useState(false);  
  const [hotdog, setHotDog] = useState(false);  
  const [src, setSrc] = useState("");

  const identifyFromFile = (event) => {
    const { target: { files } } = event;
    const [file,] = files || [];
    const filename = file.name;

    if (!file) {
      return;
    }

    console.log(file);

    setProcessed(false);
    setProcessing(true);
    setSrc("");

    Storage.put(filename, file, {
      level: 'private',
      contentType: file.type
    })
    .then( result => {
      
      console.log('S3 upload: ', result);

      return Predictions.identify({
        labels: {
          source: {
            file,
          },
          type: "ALL" // "LABELS" will detect objects , "UNSAFE" will detect if content is not safe, "ALL" will do both default on aws-exports.js
        }
      });

    })
    .then( result => {
      console.log('result: ', result)
      const labels = result.labels.map(l => l.name)
      console.log('labels: ', labels)

      var isHotDog = false;
      result.labels.forEach( l => {
        console.log(l.name.toUpperCase());
        // Check for Hot Dog
        if (l.name.toUpperCase() === "HOT DOG") {
          console.log('Found Hot Dog');
          isHotDog = true;
        }
      });

      setHotDog(isHotDog);
      setProcessing(false);

      return Storage.get(filename, { 
        level: 'private' 
      });
    })
    .then ( (result) => { 
      console.log('S3 download: ', result); 
      setSrc(result);
      setProcessed(true);
    })

  }

  return (
    <Container>
      <Jumbotron className="text-center">
        <h1>The "New" Seefood App</h1>
        <p className="font-italic">
        <Image className="thumbnail" src={Thumbnail} roundedCircle /> What if I told you about an app that can identify any food in the world.
        </p>
        {
          !processing &&        
            <Form className="text-left">
              <Form.File 
                id="file-upload"
                label="Upload an image of your meal"
                custom
                onChange={identifyFromFile}
              />
            </Form>     
        }        
      </Jumbotron>   
      <div className="text-center">
        {
          processing &&
          <Spinner animation="border" variant="secondary" />
        }        
        {
          processed && hotdog &&
          <h1><Badge pill variant="success"><FontAwesomeIcon icon={faCheckCircle} /> Hot Dog</Badge></h1>
        }
        {
          processed && !hotdog &&
          <h1><Badge pill variant="danger"><FontAwesomeIcon icon={faTimesCircle} /> Not Hot Dog</Badge></h1>
        }
        { 
          src && 
          <Image className="source" src={src} fluid />
        }      
      </div>          
    </Container> 
  );
}

export default withAuthenticator(App);
