/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:9f9af2c0-6455-4607-9b11-24a8210c9aa1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jMpxWeZYO",
    "aws_user_pools_web_client_id": "38b7f7to1jfm1fknstvhfsjffp",
    "oauth": {},
    "aws_user_files_s3_bucket": "newseefoodapp71c748a4eb6b4fbdacafa9ce432ad621124628-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "predictions": {
        "identify": {
            "identifyLabels": {
                "proxy": false,
                "region": "us-east-1",
                "defaults": {
                    "type": "LABELS"
                }
            }
        }
    }
};


export default awsmobile;
